//印尼语
export default {
  pay: {
    waitPay: "Menunggu Pembayaran",
    purchase: "Membeli",
    totalAmount: "Jumlah Total",
    otherMethods: "Metode Pembayaran Lainnya",
    payAmount: "Jumlah Pembayaran",
    payMethod: "Metode Pembayaran",
    payTime: "Waktu Pembayaran",
    complete: "Selesai",
    successfulPayment: "Pembayaran Berhasil",
  },
  language: {
    switchLanguage: "tukar bahasa",
    return: "kembali",
  },
  order: {
    payOrder: "Bayar pesanan",
    payAmount: "Jumlah pembayaran",
    payType: "Cara Pembayaran",
    payStatus: "Status pembayaran",
    selectPayment: "Pilih metode pembayaran",
    copy: "menyalin",
    copySuccessful: "Salin Berhasil",
    expires: "Tidak sah",
    orderId: "Id pemesanan",
    orderDetail: "Detail pesanan",
    refresh: "Menyegarkan",
    havePaid: "Dibayar",
    retry: "Mencoba",
    use: "menggunakan",
    problems: "Apakah Anda mengalami masalah dengan pembayaran?",
    otherOne1: "Cara membayar melalui ATM",
    otherOne2: "1.Kunjungi ATM terdekat sebelum kedaluwarsa.",
    otherOne3: "2.Lakukan transfer menggunakan fungsi transfer ATM.",
    otherOne4: "3.Verifikasi kode pembayaran di atas dan masukkan transfer.",
    otherOne5: "4.Lakukan pembayaran sesuai dengan jumlahnya.",
    otherTwo1: "Cara membayar via online",
    otherTwo2: "1.Akses bank online yang sesuai sebelum kedaluwarsa.",
    otherTwo3: "2.Lakukan transfer menggunakan fungsi transfer bank online.",
    otherTwo4:
      "3.Verifikasi kode pembayaran di atas dan masukkan kode tersebut.",
    otherTwo5: "4.Bayar sesuai dengan jumlahnya.",
    otherThree1: "Cara membayar melalui M-Banking",
    otherThree2: "1.Kunjungi bank terdekat sebelum kedaluwarsa.",
    otherThree3: "2.Beritahu kasir untuk melakukan pembayaran.",
    otherThree4: "3.Katakan atau tunjukkan kode pembayaran di atas.",
    otherThree5: "4.Bayar sesuai dengan jumlahnya.",
    rechargeSuccessful: "Isi ulang berhasil",
    gold: "Emas",
    rewardCoins: "Hadiahi koin",
    ovo: "Pembayaran OVO",
    amountDue: "Jumlah yang Harus Dibayar",
    changePayment: "Ganti Cara Bayar",
    proceedtoPay: "Lanjut Bayar",
    phoneNumber: "Nomor Telepon",
    phoneNumber: "Nomor telepon",
    phoneNumber2: "Silakan masukkan nomor telepon yang valid",
    phoneNumber3: "Silakan masukkan nomor telepon Anda",
    orderForm1: "Pesanan telah dibuat",
    orderForm2: "Silakan lanjutkan ke",
    orderForm3: "Menyelesaikan pembayaran",

  },

  recharge: {
    recharge: "Isi Ulang",
    coins: "Koin",
    bonus: "Bonus",
    GoldCoinRechargeRules: "Aturan Pengisian Koin Emas:",
    goldCoinsAreVirtual:
      "Koin emas adalah barang virtual dan tidak dapat dikembalikan. Mereka hanya digunakan untuk membuka konten premium dalam aplikasi.",
    expiredDeletion:
      "Kesetaraan pengisian: 1 koin emas = 1 bonus. Harap dicatat bahwa pengembalian dana pengisian ulang tidak didukung.",
    expiredWillBeInvalidated:
      "Pastikan untuk menggunakan koin bonus yang diperoleh melalui pengisian ulang atau partisipasi dalam kegiatan dalam periode berlaku, karena mereka akan menjadi tidak valid setelah kedaluwarsa.",
    goldCoinsNotReceived:
      "Jika koin tidak dikreditkan tepat waktu setelah pengisian ulang, silakan keluar dari aplikasi dan periksa lagi.",
    rechargeAgreement: "Isi ulang menyiratkan",
    protocol: "《Perjanjian Isi Ulang》",
    specialOffer: "Pertama",
    extra: "Ekstra",
    unlockAll: "Buka kunci episode secara otomatis",
    day: "Hari",
    subscribe: "Berlangganan",
    price: "Harga",
    goldMall: "Mal Koin Emas",
    balance: "Keseimbangan",
    unlock: "Buka kunci",
    episode: "Episode",
    timeLimited: "Setoran pertama dengan waktu terbatas",
    time: 'Waktu terbatas',
    otherOptions: "Pilihan lain",
    luckyUser: "Selamat telah terpilih menjadi pengguna yang beruntung dengan paket diskon terbaik",
    HH: 'HH',
    MM: 'mm',
    SS: 'ss',
    payNow: "Bayar sekarang",
    unlockWeek: 'satu minggu buka semua seri',
    unlockMonth: 'satu bulan buka semua seri',
    unlockYear: 'satu tahun buka semua seri',
    renew: 'Perpanjangan',
    newUser: 'hadiah untuk pengguna baru',
    only: 'Wanya sekali',
    left: ''
  },
  home: {
    loading: "Memuat data...",
    all: "eps",
    ep: "eps",
    classification: "klasifikasi",
    recentlywatched: "Baru saja melihat",
    collection: "Tandai",
    language: "Bahasa",
    logOut: "Keluar",
    refresh: "Segar",
    login: "Masuk",
    guest: 'Tamu',
    rechargeNow: 'Isi Ulang Sekarang',
    notification: 'Notifikasi',
    confirmLogOut: 'Konfirmasi Keluar?',
    confirm: 'Konfirmasi',
    cancel: 'Batal',
    getID: 'Dapatkan ID?',
    howtoObtainUserID: 'Cara Mendapatkan ID Pengguna',
    return1: 'Kembali ke reelbuzz di',
    return2: '"Saya"',
    return3: 'Salin UID di halaman',
    please: 'Masukkan ID 8-10.',
    loginSuccessful: 'Login berhasil',
    exit: 'Keluar berhasil',
    returntoReelBuzz: 'Kembali ke ReelBuzz untuk memeriksa'
  },
  otherpage: {
    otherpage1: "Setelah mengisi koin emas, koin emas tertunda dalam tiba",
    otherpage2:
      "Karena masalah jaringan, kedatangan koin emas mungkin tertunda. Dalam kasus seperti itu, Anda perlu menunggu jaringan membaik dan kemudian pergi ke halaman pengisian ulang. Jika masih tidak berhasil, Anda akan menerima pengembalian dana dari Google atau Apple setelah 3 hari.",
    otherpage3:
      "Jika koin emas tidak dikreditkan tepat waktu, mungkin karena kemacetan jaringan yang menyebabkan penundaan. Jangan khawatir, sistem akan memverifikasi ulang, dan umumnya, koin akan dikreditkan secara otomatis pada pukul 18:00 pada hari kerja kedua. Jika karena alasan lain koin masih belum dikreditkan, jumlah tersebut akan dikembalikan ke akun pembayaran asli dalam waktu tiga hari kerja.",
    otherpage4: "Umpan Balik",
    inviteFriends1: "Seri TV populer",
    inviteFriends2:
      "Tonton satu episode setelah masuk ke ReelBuzz, teman bisa mendapatkan hadiah BOINS",
    inviteFriends3: "Petunjuk：",
    inviteFriends4: "Unduh",
    inviteFriends5: "Seri TV populer",
    inviteFriends6: "Invite friends",
    inviteFriends7: "Tidak dapat berlangganan lagi",
    jelaskan1:
      "Instruksi Langganan:\n• Pembelian Keanggotaan: Periode pembelian adalah 30 hari, 365 hari, atau 7 hari. Harga pembelian tergantung pada produk.\n• Perpanjangan Langganan: Akun Google Pay akan dikenakan biaya 24 jam sebelum tanggal kedaluwarsa. Setelah pembayaran berhasil, langganan akan diperpanjang untuk satu periode.Layanan langganan akan berlanjut hingga dibatalkan.\n• Batalkan Langganan: Buka Google Pay - Langganan - Pilih Reelbuzz untuk membatalkan langganan.\n• Tidak Ada Pengembalian Dana: Setelah layanan keanggotaan diaktifkan, tidak ada pengembalian dana yang akan diberikan, dan tidak berlaku untuk kebijakan pengembalian tanpa alasan.\n• Jika Anda memiliki pertanyaan tentang layanan keanggotaan, klik Saya - Umpan Balik, masuk ke halaman umpan balik, dan hubungi kami. Staf layanan pelanggan kami akan menangani masalah Anda.",
  },
  whatsapp: {
    paling: 'Paling populer',
    countDown: 'Hitung Mundur',
    extra: 'Ekstra',
    rules: 'Aturan Acara',
    eventDuration: 'Durasi Acara: Diskon isi ulang ini berlaku selama 24 jam.',
    eventDuration2: 'Cara Berpartisipasi: Pengguna dapat mengisi ulang melalui halaman acara untuk mendapatkan hadiah tambahan.',
    eventDuration3: 'Batas Partisipasi: Setiap pengguna hanya memiliki satu kesempatan untuk berpartisipasi dan hanya dapat memilih salah satu dari dua opsi isi ulang.',
    rechargeAgreement: 'Isi ulang berarti menyetujui "Perjanjian Isi Ulang"',
    exclusive: 'Acara Eksklusif'
  }
};
