import { getItem } from "./storage";

// 是否Android
export const isAndroid = () => {
  return /Android/.test(navigator.userAgent);
};
// 是否IOS
export const isIOS = () => {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
};
//随机生成uuid 随机数+时间戳
export const generateUniqueId = () => {
  // 使用当前时间戳作为基础
  const timestamp = new Date().getTime();
  // 生成一个随机数
  const randomNumber = Math.floor(Math.random() * 1000000);
  // 结合时间戳和随机数生成唯一标识符
  const uniqueId = timestamp.toString(16) + randomNumber.toString(16);
  return uniqueId;
};
//获取URL参数
export const getURLParameter = (name) => {
  var currentURL = window.location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(currentURL);
  return results === null
    ? ""
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};
//获取Cookie
export const getCookie = (cookieName) => {
  const strCookie = document.cookie;
  const cookieList = strCookie.split(";");
  for (let index = 0; index < cookieList.length; index++) {
    const arr = cookieList[index].split("=");
    if (cookieName === arr[0].trim()) {
      return arr[1];
    }
  }
  return "";
};
//获取浏览器默认语言
export const getBrowserLang = () => {
  const defaultLanguage = (navigator.language || navigator.userLanguage)
    .toUpperCase()
    .slice(0, 2);
  return defaultLanguage;
};
//获取设备类型
export const getClientType = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  if (/Windows Phone/.test(userAgent)) {
    return "Windows Phone";
  }
  return "Desktop";
};
// 转成分秒
export const convertTime = (seconds) => {
  seconds = parseInt(seconds);
  var minutes =
    Math.floor(seconds / 60) < 10
      ? "0" + Math.floor(seconds / 60)
      : Math.floor(seconds / 60);
  var remainingSeconds =
    seconds % 60 < 10 ? "0" + (seconds % 60) : seconds % 60;
  return minutes + ":" + remainingSeconds;
};

export const formatNumberWithK = (num) => {
  if (num > 1000) {
    return (num / 1000).toFixed(1) + "k";
  }
  return num.toString();
};
// 添加千位符
export const toThousands = (num) => {
  let formatter = new Intl.NumberFormat("en-US");
  if (getItem('basic').language == 4) {
    formatter = new Intl.NumberFormat("id-ID");
  }
  let formattedNumber = formatter.format(num);
  return formattedNumber;
}