//泰语
export default {
  pay: {
    waitPay: "รอการชำระเงิน",
    purchase: "การซื้อ",
    totalAmount: "ยอดรวม",
    otherMethods: "วิธีการชำระเงินอื่น",
    payAmount: "จำนวนเงินที่ต้องชำระ",
    payMethod: "วิธีการชำระเงิน",
    payTime: "เวลาการชำระเงิน",
    complete: "เสร็จสิ้น",
    successfulPayment: "การชำระเงินสำเร็จ",
  },
  // 充值
  recharge: {
    recharge: "เติมเงิน",
    coins: " เหรียญ",
    bonus: " เหรียญรางวัล",
    GoldCoinRechargeRules: "กฎกติกาเกี่ยวกับเหรียญทอง:",
    goldCoinsAreVirtual:
      "เหรียญทองเป็นสิ่งมองไม่เห็นและไม่สามารถคืนได้ ใช้เพื่อปลดล็อกเนื้อหาที่เสียค่าใช้จ่ายในแอปพลิเคชั่นนี้เท่านั้น",
    expiredDeletion:
      "ค่าเติมเทียบเท่า: 1 เหรียญทอง = 1 โบนัส โปรดทราบว่าไม่สนับสนุนการคืนเงินค่าเติม",
    expiredWillBeInvalidated:
      "โปรดใช้เหรียญโบนัสที่ได้รับจากการเติมเงินหรือการเข้าร่วมกิจกรรมภายในระยะเวลาที่ถูกต้อง เมื่อหมดอายุจะถูกยกเลิกการใช้งาน",
    goldCoinsNotReceived:
      " หากเหรียญไม่ได้เข้าบัญชีทันทีหลังจากเติมเงิน กรุณาออกจากแอปและตรวจสอบอีกครั้ง",
    rechargeAgreement: "การเติมเงินแสดงถึงการยินยอมต่อ",
    protocol: "《ข้อตกลงการเติมเงิน》",
    specialOffer: "ราคาพิเศษ",
    extra: "เพิ่มเติม",
  },
  home: {
    refresh: "รีเฟรช",
    login: "เข้าสู่ระบบ",
  },
};
