export default {
  pay: {
    waitPay: "等待支付",
    purchase: "购买商品",
    totalAmount: "总金额",
    otherMethods: "其他支付方式",
    payAmount: "付款金额",
    payMethod: "付款方式",
    payTime: "付款时间",
    complete: "完成",
    successfulPayment: "成功支付",
    applePay: "苹果支付",
    googlePay: "谷歌支付",
    googlePay: "谷歌支付",
  },
  language: {
    switchLanguage: "切换语言",
    return: "返回",
  },
  order: {
    payOrder: "支付订单",
    payAmount: "支付金额",
    payType: "支付方式",
    payStatus: "支付状态",
    selectPayment: "选择支付方式",
    copy: "复制",
    copySuccessful: "复制成功",
    expires: "后失效",
    orderId: "订单ID",
    orderDetail: "订单详情",
    refresh: "刷新",
    havePaid: "我已支付",
    retry: "重试",
    use: "使用",
    problems: "是否在支付中遇见问题?",
    otherOne1: "通过ATM支付的方法",
    otherOne2: "1.在失效前访问最近的ATM",
    otherOne3: "2.通过ATM转账功能转账",
    otherOne4: "3.核对上面的付款码并输入转账",
    otherOne5: "4.根据金额支付",
    otherTwo1: "通过在线支付的方法",
    otherTwo2: "1.在失效前访问对应线上银行",
    otherTwo3: "2.通过线上银行转账功能转账",
    otherTwo4: "3.核对上面的付款码并输入该付款码",
    otherTwo5: "4.根据金额支付",
    otherThree1: "通过银行支付的方法",
    otherThree2: "1.在失效前访问最近的银行",
    otherThree3: "2.通知收银员付款",
    otherThree4: "3.告诉或出示上面的付款码",
    otherThree5: "4.根据金额支付",
    rechargeSuccessful: "充值成功",
    gold: "金币",
    rewardCoins: "奖励币",
    ovo: "OVO支付",
    amountDue: "需支付",
    changePayment: "更改支付方式",
    proceedtoPay: "前往支付",
    phoneNumber: "电话号码",
    phoneNumber: "手机号",
    phoneNumber2: "请输入正确手机号",
    phoneNumber3: "请输入手机号",
    orderForm1: "订单已生成",
    orderForm2: "请前往",
    orderForm3: "完成支付",
  },
  // 充值
  recharge: {
    recharge: "充值",
    coins: "金币",
    bonus: "奖励币",
    GoldCoinRechargeRules: "金币规则：",
    goldCoinsAreVirtual:
      "金币是虚拟物品，不能退还。仅用于本产品解锁应用程序中的付费内容。",
    expiredDeletion: "充值等价：1金币=1奖金。请注意，不支持充值退款。",
    expiredWillBeInvalidated:
      "您通过充值或参加活动获得的奖励币请在有效期内使用完，过期将作废。",
    goldCoinsNotReceived: "如充值金币未及时到账，请退出应用再次进入查看。",
    rechargeAgreement: "充值即已同意",
    protocol: "《充值协议》",
    specialOffer: "特价",
    extra: "额外",
    unlockAll: "自动解锁剧集",
    day: "天",
    subscribe: "订阅",
    price: "价格",
    goldMall: "金币商城",
    balance: "余额",
    unlock: "解锁",
    episode: "集",
    timeLimited: "限时首充",
    time: '限时',
    otherOptions: "其他选择",
    luckyUser: "恭喜您被选为幸运用户，最优惠折扣套餐",
    HH: '时',
    MM: '分',
    SS: '秒',
    payNow: "现在支付",
    unlockWeek: '一周内解锁所有系列',
    unlockMonth: '一月内解锁所有系列',
    unlockYear: '一年内解锁所有系列',
    renew: '续订',
    newUser: '新用户奖励',
    only: '仅剩',
    left: ''
  },
  home: {
    loading: "数据加载中...",
    all: "共",
    ep: "集",
    classification: "分类",
    recentlywatched: "观看过",
    collection: "收藏",
    language: "语言",
    logOut: "退出登录",
    refresh: "刷新",
    login: "登录",
    guest: '游客',
    rechargeNow: '立即充值',
    notification: '通知',
    confirmLogOut: '是否确认退出登录',
    confirm: '确定',
    cancel: '取消',
    getID: '获取ID?',
    howtoObtainUserID: '如何获取用户ID？',
    return1: '返回reelbuzz在',
    return2: '“我的”',
    return3: '页面上复制UID',
    please: '请输入8~10的ID',
    loginSuccessful: '登陆成功',
    exit: '退出成功',
    returntoReelBuzz: '返回Reelbuzz查看'
  },
  otherpage: {
    otherpage1: "充值金币后,金币迟迟不到账",
    otherpage2:
      "会因为网络原因延迟到账,这种情况需要等到网络良好后到充值页面即可到账,如果还是不行3天后会收到谷歌或者苹果的退款。",
    otherpage3:
      "如果金币未及时到账，可能由于网络繁忙原因到账延迟请不用担心，系统重新核实，一般在第二个工作日晚上18:00自动到账，如果因其他原因导致金币仍未到账，三个工作日金额会退回原支付账户。",
    otherpage4: "问题反馈",
    inviteFriends1: "邀请观看",
    inviteFriends2: "进入ReelBuzz后完成一集观看，好友即可获得BOINS奖励",
    inviteFriends3: "提示：",
    inviteFriends4: "下载",
    inviteFriends5: "热门好剧",
    inviteFriends6: "邀请好友",
    inviteFriends7: "不可重复订阅",
    jelaskan1:
      "订阅说明：\n• 会员购买：购买期为30天 、365天、7天。购买价格取决于产品。\n• 订阅续费：Google Pay账户将在到期日期前24小时收取费用，支付成功后订阅器将延长一个周期。订阅服务会持续到取消订阅为止。\n• 取消订阅：转到Google Pay-订阅-选择Reelbuzz以取消订阅。 \n• 会员服务一经启用，恕不退款，亦不适用无理由退货政策。。\n• 如果你对会员服务有任何疑问，点击我的-问题反馈，进入反馈页面与我们联系，我们的客服人员将会为您处理您的问题。",
  },
  whatsapp: {
    paling: '最热门',
    countDown: '倒计时',
    extra: '额外',
    rules: '活动规则',
    eventDuration: '活动时间：此充值折扣活动有效期为24小时。',
    eventDuration2: '活参与方式：用户通过活动页面进行充值，可获得额外奖励。',
    eventDuration3: '参与限制：每位用户只有一次参与机会，并且只能选择两个充值选项',
    rechargeAgreement: '充值即代表同意《充值协议》',
    exclusive: '专属活动'
  }
};
