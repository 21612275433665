<template>
  <div class="root">
    <!-- 路由出口 -->
    <router-view></router-view>
  </div>
</template>

<script>
import store from "./store";
import { setItem } from "./utils/storage";
import { isAndroid, isIOS, getBrowserLang } from "@/utils/index";
export default {
  data() {
    return {};
  },
  created() {
    if (getBrowserLang() == "EN") {
      setItem("language", 2);
      this.$i18n.locale = "EN";
    } else if (getBrowserLang() == "ZH") {
      setItem("language", 3);
      this.$i18n.locale = "ZH";
    } else if (getBrowserLang() == "ID") {
      setItem("language", 4);
      this.$i18n.locale = "IN";
    } else {
      setItem("language", 4);
      this.$i18n.locale = "IN";
    }
    // 获取根元素
    const root = document.documentElement;
    // 设置字体大小
    function setFontSize(size) {
      root.style.setProperty("font-size", `${size}px`);
    }

    // 示例：根据窗口宽度动态调整字体大小
    function adjustFontSize() {
      const width = window.innerWidth;
      if (width < 1200) {
        setFontSize(37.5); // 小屏幕
      } else if (width >= 1200) {
        setFontSize(40); // 大屏幕
      }
    }
    // 监听窗口大小变化
    window.addEventListener("resize", () => {
      adjustFontSize();
    });
    // 初始化时调用一次
    adjustFontSize();
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
