// 葡萄牙语
export default {
  pay: {
    waitPay: "Esperando Pagamento",
    purchase: "Compra",
    totalAmount: "Montante Total",
    otherMethods: "Outros Métodos de Pagamento",
    payAmount: "Montante de Pagamento",
    payMethod: "Método de Pagamento",
    payTime: "Hora de Pagamento",
    complete: "Completo",
    successfulPayment: "Pagamento Bem-sucedido",
  },
  // 充值
  recharge: {
    recharge: "Recarrega",
    coins: "Moedas",
    bonus: "moedas de recompensa",
    GoldCoinRechargeRules: "Regras das Moedas:",
    goldCoinsAreVirtual:
      "As moedas são itens virtuais e não podem ser devolvidas. Apenas são utilizadas para desbloquear conteúdo pago dentro desta aplicação.",
    expiredDeletion:
      "Equivalente ao carregamento: 1 moeda = 1 bónus. Por favor, tenha em mente que não é suportado o reembolso do carregamento.",
    expiredWillBeInvalidated:
      "Por favor, utilize as moedas de bónus que tenha obtido através do carregamento ou da participação em atividades dentro do período de validade. Serão anuladas uma vez expiradas.",
    goldCoinsNotReceived:
      "Se as moedas não forem creditadas a tempo após a recarga, saia do aplicativo e verifique novamente.",
    rechargeAgreement: "O carregamento implica a aceitação do",
    protocol: "《Contrato de Recarga》",
    specialOffer: "Preço especial",
    extra: "Extra",
  },
  home: {
    refresh: "Atualizar",
    login: "Entrar",
  },
};
