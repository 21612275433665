//越南语
export default {
  pay: {
    waitPay: "Chờ thanh toán",
    purchase: "Mua hàng",
    totalAmount: "Tổng số tiền",
    otherMethods: "Các phương thức thanh toán khác",
    payAmount: "Số tiền thanh toán",
    payMethod: "Phương thức thanh toán",
    payTime: "Thời gian thanh toán",
    complete: "Hoàn thành",
    successfulPayment: "Thanh toán thành công",
  },
  // 充值
  recharge: {
    recharge: "Nạp tiền",
    coins: "Xu",
    bonus: "tiền thưởng",
    GoldCoinRechargeRules: "Quy tắc về Đồng xu:",
    goldCoinsAreVirtual:
      "Đồng xu là vật phẩm ảo, không thể hoàn lại. Chúng chỉ được sử dụng để mở khóa nội dung trả phí trong ứng dụng này.",
    expiredDeletion:
      "Tương đương với việc nạp tiền: 1 Đồng xu = 1 Tiền thưởng. Vui lòng lưu ý, không hỗ trợ hoàn tiền nạp.",
    expiredWillBeInvalidated:
      "Vui lòng sử dụng Đồng xu thưởng đã nhận được thông qua nạp tiền hoặc tham gia hoạt động trong khoảng thời gian hiệu lực. Đồng xu sẽ không còn hiệu lực khi hết hạn.",
    goldCoinsNotReceived:
      "Nếu không có xu được ghi nhận kịp thời sau khi nạp tiền, vui lòng thoát khỏi ứng dụng và kiểm tra lại.",

    rechargeAgreement: "Nạp tiền là đồng ý với",
    protocol: "《Thỏa thuận nạp tiền》",
    specialOffer: "Giá đặc biệt",
    extra: "Thêm",
  },
  home: {
    refresh: "Tân trang",
    login: "Đăng nhập",
  },
};
