// 在 index.js 文件进行配置
// 导入所需的库和资源： 代码中导入了 Vue.js 框架、Vue I18n 扩展以及各种语言版本的翻译资源，包括 Element UI 组件库的翻译。
import Vue from "vue";
import VueI18n from "vue-i18n";
import ZH from "./ZHCN";
import FT from "./FTCN";
import EN from "./EN";
import IN from "./IN";
import TH from "./TH";
import FI from "./FI";
import VI from "./VI";
import JA from "./JA";
import KO from "./KO";
import SP from "./SP";
import PO from "./PO";
import FR from "./FR";
import AR from "./AR";
import { getBrowserLang } from "@/utils";

Vue.use(VueI18n);

// 1 // 繁体中文
// 2 //英文
// 3 //简体中文
// 4 //印尼语
// 5 // 泰语
// 6 // 菲律宾语
// 7 //越南语
// 8 // 日语
// 9 // 韩语
// 10 // 西班牙语
// 11 // 葡萄牙语
// 12 // 法语
// 13 // 阿拉伯语
const i18n = new VueI18n({
  locale: getBrowserLang() || EN,
  messages: {
    ZH: ZH,
    FT: FT,
    EN: EN,
    IN: IN,
    TH: TH,
    FI: FI,
    VI: VI,
    JA: JA,
    KO: KO,
    SP: SP,
    PO: PO,
    FR: FR,
    AR: AR,
  },
});
// 导出 I18n 实例
export default i18n;
