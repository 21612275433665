// 西班牙语
export default {
  pay: {
    waitPay: "Esperando Pago",
    purchase: "Compra",
    totalAmount: "Monto Total",
    otherMethods: "Otros Métodos de Pago",
    payAmount: "Cantidad de Pago",
    payMethod: "Método de Pago",
    payTime: "Tiempo de Pago",
    complete: "Completo",
    successfulPayment: "Pago Exitoso",
  },
  // 充值
  recharge: {
    recharge: "Recarga",
    coins: "Monedas",
    bonus: "monedas de recompensa",
    GoldCoinRechargeRules: "Reglas de Monedas:",
    goldCoinsAreVirtual:
      "Las monedas son artículos virtuales y no pueden ser devueltas. Solo se utilizan para desbloquear contenido de pago dentro de esta aplicación.",
    expiredDeletion:
      "Equivalente a la carga: 1 moneda = 1 bonificación. Por favor ten en cuenta que no se admite el reembolso de la carga.",
    expiredWillBeInvalidated:
      "Por favor, utiliza las monedas de bonificación que hayas obtenido a través de la carga o participación en actividades dentro del período de validez. Se invalidarán una vez caducadas.",
    goldCoinsNotReceived:
      "Si las monedas no se acreditan a tiempo después de la recarga, salga de la aplicación y verifique nuevamente.",
    rechargeAgreement: "La recarga implica la aceptación del",
    protocol: "《Contrato de recarga》",
    specialOffer: "Precio especial",
    extra: "Adicional",
  },
  home: {
    refresh: "Refrescar",
    login: "Iniciar sesión",
  },
};
