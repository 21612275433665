export default [
  //重定向
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: () =>
      import(/* webpackChunkName: "login_home_welcome" */ "@/views/home"),
    name: "home",
  },
  {
    path: "/numberPayment",
    component: () =>
      import(/* webpackChunkName: "login_home_welcome" */ "@/views/numberPayment"),
    name: "numberPayment",
  },
  {
    path: "/orderStatus",
    component: () =>
      import(/* webpackChunkName: "login_home_welcome" */ "@/views/orderStatus"),
    name: "orderStatus",
  },
  {
    path: "/otherPay",
    component: () =>
      import(/* webpackChunkName: "login_home_welcome" */ "@/views/otherPay"),
    name: "otherPay",
  },
];
