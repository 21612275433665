import { getItem, setItem } from "@/utils/storage";
export default {
	actions: {},
	mutations: {
		//登录状态
		setUser(state, user) {
			state.access_token = user.authorization;
			state.country = user.country;
			state.device = user.device;
		}
	},
	getters: {
		access_token: (state) => state.access_token,
		country: (state) => state.country,
		device: (state) => state.device,
	},
	//登录状态
	state: { user: '', access_token: '' }
};
