// 日语
export default {
  pay: {
    waitPay: "支払いを待つ",
    purchase: "購入する",
    totalAmount: "合計金額",
    otherMethods: "その他の支払い方法",
    payAmount: "支払い金額",
    payMethod: "支払い方法",
    payTime: "支払い時間",
    complete: "仕上げる",
    successfulPayment: "成功な支払い",
  },
  // 充值
  recharge: {
    recharge: "チャージ 충전",
    coins: "コイン ",
    bonus: "報酬コイン",
    GoldCoinRechargeRules: " 金貨の規則：",
    goldCoinsAreVirtual:
      "金貨は仮想物品であり、返品できません。 本製品のアプリ内課金コンテンツのロック解除にのみ使用されます。",
    expiredDeletion:
      "  チャージ相当：1金貨=1ボーナス。 チャージの返金はサポートされていないことに注意してください。",
    expiredWillBeInvalidated:
      "チャージまたはイベント参加によって得られたボーナスコインは、有効期限内に使用してください。 期限が切れると無効になります。",
    goldCoinsNotReceived:
      "コインがチャージ後すぐにクレジットされない場合、アプリを終了して再度確認してください。",
    rechargeAgreement: "チャージは同意済み",
    protocol: "『チャージプロトコル』",
    specialOffer: "特別価格",
    extra: "追加",
  },
  home: {
    refresh: "リフレッシュ",
    login: "ログイン",
  },
};
