//英文
export default {
  pay: {
    waitPay: "Waiting for Payment",
    purchase: "Purchase",
    totalAmount: "Total Amount",
    otherMethods: "Other Payment Methods",
    payAmount: "Payment Amount",
    payMethod: "Payment Method",
    payTime: "Payment Time",
    complete: "Complete",
    successfulPayment: "Successful Payment",
  },
  language: {
    switchLanguage: "switch Language",
    return: "Return",
  },
  order: {
    payOrder: "Pay order",
    payAmount: "Payment amount",
    payType: "payment method",
    payStatus: "Payment status",
    selectPayment: "Select Payment Method",
    copy: "Copy",
    copySuccessful: "Copy Successful",
    expires: "Expired",
    orderId: "Order ID",
    orderDetail: "Order Details",
    refresh: "Refresh",
    havePaid: "I have paid",
    retry: "Retry",
    use: "Use",
    problems: "Are you having problems with payment?",
    otherOne1: "How to pay through ATM",
    otherOne2: "1.Visit the nearest ATM before it expires.",
    otherOne3: "2.Make a transfer using the ATM's transfer function.",
    otherOne4: "3.Verify the payment code above and enter the transfer.",
    otherOne5: "4.Make the payment according to the amount.",
    otherTwo1: "How to pay via online",
    otherTwo2: "1.Access the corresponding online bank before it expires.",
    otherTwo3: "2.Make a transfer using the online bank's transfer function.",
    otherTwo4: "3.Verify the payment code above and enter it.",
    otherTwo5: "4.Pay according to the amount.",
    otherThree1: "How to pay through mobile banking",
    otherThree2: "1.Visit the nearest bank before it expires.",
    otherThree3: "2.Notify the cashier to make the payment.",
    otherThree4: "3.Tell or show the payment code above.",
    otherThree5: "4.Pay according to the amount.",
    rechargeSuccessful: "Recharge successful",
    gold: "Gold",
    rewardCoins: "Reward coins",
    ovo: "OVO Payment",
    amountDue: "Amount Due",
    changePayment: "Change Payment",
    proceedtoPay: "Proceed to Pay",
    phoneNumber: "Phone Number",
    phoneNumber: "Phone number",
    phoneNumber2: "Please enter a valid phone number",
    phoneNumber3: "Please enter your phone number",
    orderForm1: "Order has been created",
    orderForm2: "Please proceed to",
    orderForm3: "Complete the payment",

  },
  recharge: {
    recharge: "Top-up",
    coins: "Coins",
    bonus: "Bonus",
    GoldCoinRechargeRules: "Gold Coin Recharge Rules:",
    goldCoinsAreVirtual:
      "Gold coins are virtual items and cannot be refunded. They are only used to unlock premium content within the app.",
    expiredDeletion:
      "Recharge equivalence: 1 gold coin = 1 bonus. Please note that recharge refunds are not supported.",
    expiredWillBeInvalidated:
      "Please ensure to utilize the bonus coins obtained through recharge or participation in activities within their validity period, as they will become invalid upon expiration.",
    goldCoinsNotReceived:
      "If the coins are not credited in time after recharging,",
    rechargeAgreement: "Top-up implies agreement to the",
    protocol: "《Top-up Agreement》",
    specialOffer: "Special Offer",
    extra: "Extra",
    unlockAll: "Automatically unlock episodes",
    day: "day",
    subscribe: "Subscribe",
    price: "Price",
    goldMall: "Gold Coin Mall",
    balance: "Balance",
    unlock: "Unlock",
    episode: "Episode",
    timeLimited: "Limited time first deposit",
    time: 'Limited time',
    otherOptions: "Other options",
    luckyUser: "Congratulations on being selected as a lucky user with the best discount package",
    HH: 'HH',
    MM: 'mm',
    SS: 'ss',
    payNow: "Pay Now",
    unlockWeek: 'Unlock all series within a week',
    unlockMonth: 'Unlock all series within a month',
    unlockYear: 'Unlock all series within a year',
    renew: 'Renew',
    newUser: 'New user reward',
    only: 'Only',
    left: 'left'
  },
  home: {
    loading: "Loading data...",
    all: "All",
    ep: "EP",
    classification: "Classification",
    recentlywatched: "Recently Watched",
    collection: "Bookmark",
    language: "Language",
    logOut: "Log out",
    refresh: "Refresh",
    login: "Login",
    guest: 'Guest',
    rechargeNow: 'Recharge Now',
    notification: 'Notification',
    confirmLogOut: 'Confirm Log Out?',
    confirm: 'Confirm',
    cancel: 'Cancel',
    getID: 'Obtain ID?',
    howtoObtainUserID: 'How to Obtain User ID',
    return1: 'Return to reelbuzz at',
    return2: '"My"',
    return3: 'Copy the UID on the page',
    please: 'Enter ID 8-10.',
    loginSuccessful: 'Login successful',
    exit: 'Exit successful',
    returntoReelBuzz: 'Return to ReelBuzz to check'
  },
  otherpage: {
    otherpage1:
      "After recharging gold coins, the gold coins are delayed in arriving",
    otherpage2:
      "Due to network issues, the arrival of the gold coins may be delayed. In such cases, you need to wait for the network to improve and then go to the recharge page. If it still doesn't work, you will receive a refund from Google or Apple after 3 days.",
    otherpage3:
      "If the gold coins are not credited in time, it may be due to network congestion causing delays. Don't worry, the system will re-verify, and generally, the coins will be automatically credited by 18:00 on the second business day. If for other reasons the coins are still not credited, the amount will be refunded to the original payment account within three business days.",
    otherpage4: "Feedback",
    inviteFriends1: "Invite to watch",
    inviteFriends2:
      "Watch one episode after entering ReelBuzz, friends can get BOINS rewards",
    inviteFriends3: "Reminder：",
    inviteFriends4: "Download",
    inviteFriends5: "Popular TV series",
    inviteFriends6: "Invite friends",
    inviteFriends7: "Cannot Subscribe Again",
    jelaskan1:
      "Subscription Instructions:\n• Membership Purchase: The purchase period is 30 days, 365 days, or 7 days. The purchase price depends on the product.\n• Subscription Renewal: The Google Pay account will be charged 24 hours before the expiration date. Once the payment is successful, the subscription will be extended for one period.The subscription service will continue until you cancel.\n• Cancel Subscription: Go to Google Pay - Subscriptions - Select Reelbuzz to cancel the subscription.\n• No Refund: Once the membership service is activated, no refunds will be given, and it does not apply to the no-questions-asked return policy.\n• If you have any questions about the membership service, click My - Feedback, enter the feedback page, and contact us. Our customer service staff will handle your problem.",
  },
  whatsapp: {
    paling: 'Most popular',
    countDown: 'Count Down',
    extra: 'Extra',
    rules: 'Event Rules',
    eventDuration: 'Event Duration: This recharge discount is valid for 24 hours.',
    eventDuration2: 'How to Participate: Users can recharge through the event page to receive additional rewards.',
    eventDuration3: 'Participation Restriction: Each user has only one chance to participate and can only choose one of two recharge options.',
    rechargeAgreement: 'By recharging, you agree to the Recharge Agreement',
    exclusive: 'Exclusive Event'
  }
};
