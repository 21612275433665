// 菲律宾语
export default {
  pay: {
    waitPay: "Naghihintay ng Bayad",
    purchase: "Bumili",
    totalAmount: "Kabuuang Halaga",
    otherMethods: "Iba pang mga Paraan ng Pagbabayad",
    payAmount: "Halaga ng Bayad",
    payMethod: "Paraan ng Pagbabayad",
    payTime: "Oras ng Pagbabayad",
    complete: "Tapos",
    successfulPayment: "Matagumpay na Bayad",
  },
  // 充值
  recharge: {
    recharge: "Mag-load",
    coins: "Pera",
    bonus: "premyo na pera",
    GoldCoinRechargeRules: "Mga Patakaran sa Ginto:",
    goldCoinsAreVirtual:
      "Ang ginto ay isang virtual na bagay, hindi maaaring maibalik. Ito ay ginagamit lamang para sa pag-unlock ng mga bayad na nilalaman sa aplikasyong ito.",
    expiredDeletion:
      "Katumbas ng pag-charge: 1 Ginto = 1 Bonus. Mangyaring tandaan, hindi suportado ang pagbabalik ng charge.",
    expiredWillBeInvalidated:
      "Mangyaring gamitin ang mga bonus na naipon sa pamamagitan ng pag-charge o pagsali sa mga aktibidad sa loob ng tamang panahon. Mawawalan ng bisa kapag nag-expire.",
    goldCoinsNotReceived:
      "Kung hindi agad na nagpapatala ang mga barya pagkatapos mag-recharge,  mangyaring lumabas sa app at suriin muli.",
    rechargeAgreement: " Ang pag-load ay sumasang-ayon sa ",
    protocol: "《Kasunduan sa Pag-load》",
    specialOffer: "Espesyal na presyo",
    extra: "Karagdagang",
  },
  home: {
    refresh: "Refresh",
    login: "Mag-log",
  },
};
