// 法语
export default {
  pay: {
    waitPay: "En attente de paiement",
    purchase: "Achat",
    totalAmount: "Montant Total",
    otherMethods: "Autres Méthodes de Paiement",
    payAmount: "Montant du Paiement",
    payMethod: "Méthode de Paiement",
    payTime: "Heure de Paiement",
    complete: "Complet",
    successfulPayment: "Paiement Réussi",
  },
  // 充值
  recharge: {
    recharge: "Recharge",
    coins: "Pièces",
    bonus: "de récompense",
    GoldCoinRechargeRules: "Règles des pièces :",
    goldCoinsAreVirtual:
      "Les pièces sont des objets virtuels et ne peuvent pas être remboursées. Elles ne sont utilisées que pour débloquer du contenu payant dans cette application.",
    expiredDeletion:
      "Équivalent à la recharge : 1 pièce = 1 bonus. Veuillez noter que le remboursement de la recharge n'est pas pris en charge.",
    expiredWillBeInvalidated:
      "Veuillez utiliser les pièces bonus que vous avez obtenues via la recharge ou la participation à des activités dans le délai de validité. Elles seront annulées une fois expirées.",
    goldCoinsNotReceived:
      "Si les pièces ne sont pas créditées à temps après la recharge, veuillez quitter l'application et vérifier à nouveau.",
    rechargeAgreement: "La recharge implique l'acceptation du",
    protocol: "《Contrat de recharge》",
    specialOffer: "Precio especial",
    extra: "Additionnel",
  },
  home: {
    refresh: "Rafraîchir",
    login: "Se connecter",
  },
};
