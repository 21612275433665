// 繁体中文
export default {
  pay: {
    waitPay: "等待支付",
    purchase: "購買商品",
    totalAmount: "總金額",
    otherMethods: "其他支付方式",
    payAmount: "付款金額",
    payMethod: "付款方式",
    payTime: "付款時間",
    complete: "完成",
    successfulPayment: "成功支付",
  },
  // 充值
  recharge: {
    recharge: "充值",
    coins: "金幣",
    bonus: "獎勵幣",
    GoldCoinRechargeRules: "金幣規則：",
    goldCoinsAreVirtual:
      "金幣是虛擬物品，不能退還。 僅用於本產品解鎖應用程式中的付費內容。",
    expiredDeletion: "儲值等價：1金幣=1獎金。 請注意，不支援儲值退款。",
    expiredWillBeInvalidated:
      "您透過儲值或參加活動獲得的獎勵幣請在有效期限內使用完，過期將作廢。",
    goldCoinsNotReceived: "如充值金幣未及時到賬，請退出應用再次進入查看。",
    rechargeAgreement: "充值即已同意",
    protocol: "《充值協議》",
    specialOffer: "特價",
    extra: "額外",
  },
  home: {
    refresh: "刷新",
    login: "登錄",
  },
};
