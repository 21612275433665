//阿拉伯语
export default {
  pay: {
    waitPay: "في انتظار الدفع",
    purchase: "شراء",
    totalAmount: "المبلغ الإجمالي",
    otherMethods: "طرق الدفع الأخرى",
    payAmount: "مبلغ الدفع",
    payMethod: "طريقة الدفع",
    payTime: "وقت الدفع",
    complete: "اكتمال",
    successfulPayment: "دفع ناجح",
  },
  // 充值
  recharge: {
    recharge: "شحن",
    coins: "نقود",
    bonus: "نقود جائزة",
    GoldCoinRechargeRules: "قواعد النقود:",
    goldCoinsAreVirtual:
      " لنقود هي عنصر افتراضي ولا يمكن استردادها. تستخدم فقط لفتح محتوى التطبيق المدفوع في هذا المنتج.",
    expiredDeletion:
      "تعادل الشحن: 1 نقود = 1 جائزة. يرجى ملاحظة أنه لا يتم دعم استرداد الشحن.",
    expiredWillBeInvalidated:
      " يرجى استخدام النقود التي تحصل عليها من خلال الشحن أو المشاركة في الأنشطة خلال فترة صلاحيتها، حيث أنها ستصبح غير صالحة بعد انتهاء الصلاحية.",
    goldCoinsNotReceived:
      "إذا لم تتم إضافة العملات المعدنية في الوقت المناسب بعد إعادة الشحن، يرجى الخروج من التطبيق والتحقق مرة أخرى.",
    rechargeAgreement: " الشحن يعني الموافقة على اتفاقية",
    protocol: "《الشحن》",
    specialOffer: "عرض خاص",
    extra: "إضافية",
  },
  home: {
    logOut: "退出登录",
    refresh: "تحديث",
    login: "تسجيل الدخول",
  },
};
