import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import VueMeta from "vue-meta";
import "./utils/dayjs";
//自动设置html标签字体大小
// import "amfe-flexible";

import i18n from "./lang";

import Vant from "vant";
import "vant/lib/index.css";

import "./assets/scss/index.scss";
// 创建事件总线
export const eventBus = new Vue();
// 在Vue实例原型中注册事件总线
Vue.prototype.eventBus = eventBus;
Vue.use(Vant);
Vue.use(VueMeta);

new Vue({
  store,
  i18n,
  router,
  metaInfo() {
    return {
      title: this.$store.state.metaInfo.title,
      meta: [
        {
          name: "keywords",
          content: this.$store.state.metaInfo.keywords,
        },
        {
          name: "description",
          content: this.$store.state.metaInfo.description,
        },
      ],
    };
  },
  render: (h) => h(App),
}).$mount("#app");

import VConsole from "vconsole";
process.env.NODE_ENV === "development" ? new VConsole() : "";