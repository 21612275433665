// 韩语
export default {
  pay: {
    waitPay: "지불 대기 중",
    purchase: "구매",
    totalAmount: "총 금액",
    otherMethods: "다른 지불 방법",
    payAmount: "지불 금액",
    payMethod: "지불 방법",
    payTime: "지불 시간",
    complete: "완료",
    successfulPayment: "성공적인 결제",
  },
  // 充值
  recharge: {
    recharge: "충전",
    coins: "동전",
    bonus: "보상 코인",
    GoldCoinRechargeRules: "금화 규정:",
    goldCoinsAreVirtual:
      "금화는 가상 물품으로, 반환할 수 없습니다. 이것은 이 앱 내 유료 콘텐츠를 잠금 해제하는 데에만 사용됩니다.",
    expiredDeletion:
      "충전 동등: 1금화 = 1 보너스. 환불이 지원되지 않음을 주의하십시오.",
    expiredWillBeInvalidated:
      "충전하거나 이벤트에 참여하여 획득한 보너스 동전은 유효 기간 내에 사용하십시오. 만료되면 사용할 수 없습니다",
    goldCoinsNotReceived:
      "충전 후 동전이 즉시 입금되지 않으면 앱을 종료하고 다시 확인하십시오.",
    rechargeAgreement: "충전하면 이미 동의합니다",
    protocol: "《충전 프로토콜》",
    specialOffer: "특가",
    extra: "추가",
  },
  home: {
    refresh: "리프레시",
    login: "로그인",
  },
};
